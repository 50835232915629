.ticker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%; 
  background-color: #3939A0;
  font-family: sans-serif;
}

.static-text {
  font-size: 17px;
  color: #ffffff;
  cursor: pointer;
}
