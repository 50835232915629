.testimonial-carousel {
  width: 95%;
  margin: auto;
  text-align: center;
}

.quote-parent {
  display: flex;
  justify-content: flex-end;
}
.quotes {
  height: 20px;
  width: 20px;
  float: right;
}
.testimonial-card {
  width: 30%;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  gap: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  margin: 0 15px;
}

.testimonial-card .person {
  width: 200px;
  height: 180px;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 15px;
}

.card-content h4 {
  margin: 0px;
  font-size: 0.8em;
  color: #080808;
}

.card-content p {
  font-size: 0.8em;
  color: #777;
  margin-bottom: 5px;
}

/* .slick-center .testimonial-card {
    transform: scale(1.1); 
  } 
   */
